:root {
  --primary: #ff9d33;
  --secondary: #ffffff;
  --black: #000000;
  --white: #ffffff;
}
html,
body {
  background-color: var(--secondary) !important;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  background-color: #f2f2f2 !important;
}

.logo {
  width: 106px;
  /* height: 100px; */
}
.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary);
  background-color: #fff;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}
.log-btn {
  background: linear-gradient(90deg, #ff9d33 0%, #ff9d33 100%);
  /* box-shadow: 0px 3.53261px 40.625px rgba(255, 207, 41, 0.5); */
  border: none !important;
}

.der {
  margin-top: 100px;
  margin-left: 400px;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}
.modal-title {
  font-weight: 700;
}
@media (max-width: 480px) {
  .Auth-form {
    width: 280px;
  }

  .Auth-form-content {
    padding-left: 6%;
    padding-right: 6%;
  }
}

.error-container {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--primary);
}

.dsa__dashboard {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  background-color: var(--primary);
  overflow: auto;
  height: 100vh;
  padding: 25px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.1);
  z-index: 4;
  background-color: #fff;
}

.dsa__dashboard .dsa__dash-logo img {
  max-width: 100px;
  /* margin: 0 auto; */
}

.dsa__dashboard .dsa__dash-logo {
  margin-bottom: 30px;
}

.dsa__dash-header {
  position: fixed;
  left: 300px;
  top: 0;
  width: calc(100vw - 300px);
  /* background-color: var(--primary); */
  height: 50px;
  z-index: 3;
  background-color: transparent;
}

.dsa__dash-header .dsa__dash-head_profile {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
}

.dsa__dash-header-wrapper {
  display: flex;
  padding: 10px;
  align-items: center;
}

.dsa__dash-header-wrapper .ra_dash-head_left {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 15px;
}

.ra_dash-head_right .dsa__dash-head_profile button {
  background-color: transparent;
  padding: 0;
  border: none;
}

.offcanvas-header .btn-close {
  color: #fff;
  z-index: 10;
  position: relative;
  /* filter: invert(100%) sepia(95%) saturate(0%) hue-rotate(199deg)
    brightness(106%) contrast(104%); */
}

.offcanvas.offcanvas-start {
  width: 300px !important;
}

.dsa__dash-menu {
  list-style: none;
}

.dsa__dashboard .dsa__dash-menu li a {
  color: #000;
  display: flex;
  width: 100%;
  padding: 10px 15px;
  text-decoration: none;
}

.dsa__dashboard .dsa__dash-menu li {
  margin-bottom: 5px;
  transition: all 0.5s;
}

.dsa__dashboard .dsa__dash-menu li a:focus {
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%);
  box-shadow: 0px 3.53261px 40.625px rgba(255, 207, 41, 0.5);
  border-radius: 5px;
  color: #fff;
}

.dsa__dashboard .dsa__dash-menu li a.active,
.dsa__dashboard .dsa__dash-menu li:hover a {
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%);
  /* box-shadow: 0px 3.53261px 40.625px rgba(255, 207, 41, 0.5); */
  border-radius: 5px;
  color: #fff;
}

.icon-advanced {
  background: url("../svgs/logo.svg");
  background-repeat: no-repeat;
  background-size: cover; /* stretch the background to cover the whole element */

  /* 
     still inline, but has block features
     meaning height and width can be set
  */
  display: inline-block;
  height: 20px;
  width: 20px;
}

.dsa__dashboard .dsa__dash-menu li:hover {
  border-radius: 5px;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%);
  box-shadow: 0px 3.53261px 40.625px rgba(255, 207, 41, 0.5);
  color: #fff !important;
}

.session_bg {
  background-color: #e49700 !important;
}

.border_class {
  border-color: #ffcc29 !important;
}

.button_bg {
  background-color: #ffcc29 !important;
  border: none !important;
}

.dsa__dashboard .dsa__dash-menu {
  padding-left: 0px;
}

@media (min-width: 992px) {
  .ra_dash-head_right {
    display: none;
  }

  body {
    padding-left: 300px;
  }
}

@media (max-width: 991px) {
  aside.dsa__dashboard {
    display: none;
  }

  .dsa__dash-header {
    left: 0px;
    top: 0;
    width: 100vw;
  }
}

.bars {
  width: 30px;
  height: 30px;
}

.dsa___dashboard {
  margin: 50px 30px;
  background-color: var(--primary);
}

.dsa__advice {
  margin: 80px 30px;
  background-color: var(--primary);
  border-radius: 15px;
  padding: 10px;
  background-color: #fff;
}

.dsa__advice_details {
  margin: 80px 30px;
}

.title {
  color: black;
  font-weight: bolder;
  font-size: 30px;
}

.dsa__advice__add {
  color: #fff !important;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%);
  font-weight: 500 !important;
  border: none !important;
}

.advice__table {
  width: 100%;
}

.advice__table thead tr th:first-child {
  /* background-color: var(--secondary); */
  border-radius: 10px 0 0 10px;
  background-color: #f3f3f3;
}

.advice__table thead tr th {
  /* background-color: var(--secondary); */
  color: #000;
  background-color: #f3f3f3;
}

.advice__table thead tr th:last-child {
  /* background-color: var(--secondary); */
  border-radius: 0 10px 10px 0;
  background-color: #f3f3f3;
}

.advice__table th,
.advice__table td {
  padding: 10px 15px;
  color: white;
}

.advice__table th,
.advice__table td span {
  background-color: var(--secondary);
}

.advice__table td span {
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
}

.delete_button {
  background-color: #d1d1d1;
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
}

.advice__category {
  /* background-color: var(--secondary);*/
  border-radius: 10px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  background-color: #ffcc29;
  color: #fff;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%);
}

.advice__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  color: #000;
}

.advice__desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  color: #000;
}

@media (max-width: 767px) {
  .advice__list {
    overflow: auto;
  }
  .advice__table {
    width: 767px;
  }
}

.advice_form {
  margin-top: 25px;
  margin-left: 20px;
}

.category_label {
  color: #000;
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 5px;
}

.category_dropdown {
  width: 50%;
}

.form_button {
  background-color: var(--secondary) !important;
  color: white !important;
  font-weight: 500 !important;
  border: none !important;
}

.primary_bg {
  background-color: var(--primary) !important;
}

.secondary_bg {
  background-color: var(--secondary) !important;
}

.add_inputs::-webkit-input-placeholder {
  color: #d2d2d2 !important;
}

.dsa__users {
  margin: 80px 30px;
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
}
label {
  font-family: "Inter", sans-serif;
}
.form-control {
  font-family: "Inter", sans-serif;
}

/*user*/
.user__table {
  width: 100%;
}

.user__table thead tr th:first-child {
  /* background-color: var(--secondary); */
  border-radius: 10px 0 0 10px;
  background-color: #f3f3f3;
}

.user__table thead tr th {
  /* background-color: var(--secondary); */
  color: #000;
  background-color: #f3f3f3;
}

.user__table thead tr th:last-child {
  /* background-color: var(--secondary); */
  border-radius: 0 10px 10px 0;
  background-color: #f3f3f3;
}

.user__table th,
.user__table td {
  padding: 10px 15px;
  color: white;
}

.user__table th,
.user__table td span {
  background-color: var(--secondary);
}

.user__table td span {
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
}
.user__table td h5,
.user__table td p {
  color: #000;
  margin-bottom: 0;
}

.delete_button,
.edit_button {
  background-color: #d1d1d1;
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
}
p.note {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  color: #000;
}

.advice__category {
  /* background-color: var(--secondary);*/
  border-radius: 10px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  /* background-color: #FFCC29; */
  color: #fff;
  background: linear-gradient(90deg, var(--primary) 0%, var(--primary) 100%);
}

.advice__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  color: #000;
}

.advice__desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  color: #000;
}

/*log nook*/
.logbook__table {
  width: 100%;
}

.logbook__table thead tr th:first-child {
  /* background-color: var(--secondary); */
  border-radius: 10px 0 0 10px;
  background-color: #f3f3f3;
}

.logbook__table thead tr th {
  /* background-color: var(--secondary); */
  color: #000;
  background-color: #f3f3f3;
}

.logbook__table thead tr th:last-child {
  /* background-color: var(--secondary); */
  border-radius: 0 10px 10px 0;
  background-color: #f3f3f3;
}

.logbook__table th,
.logbook__table td {
  padding: 10px 15px;
  color: white;
}

.logbook__table th,
.logbook__table td span {
  background-color: var(--secondary);
}

.logbook__table td span {
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
}
.logbook__table td h5,
.logbook__table td p {
  color: #000;
  margin-bottom: 0;
}

.line2_content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 400px;
  margin: 0 auto;
}

.user__list tr td:last-child p {
  word-break: break-word;
  max-height: 250px;
  overflow: auto;
}
.url_content {
  width: 200px;
}
input,
textarea {
  box-shadow: none !important;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 35px; /* Adjust the padding as needed */
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 15px; /* Adjust the right position as needed */
  transform: translateY(-50%);
  cursor: pointer;
}

@media (max-width: 1440px) {
  .user__list.logbook_list {
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .user__list {
    overflow: auto;
  }
  .user__table {
    width: 767px;
  }
}

.court_image {
  border: 1px solid var(--primary) !important;
  border-radius: 10px !important;
  width: 50px;
  height: 50px;
}

.avatar {
  border: 3px solid var(--primary) !important;
}

.icon-style {
  color: var(--primary);
  cursor: pointer;
}

.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.card-border {
  border: 4px solid var(--primary) !important;
}

.ds__privacy .ds__hero {
  padding: 60px 50px;
  /* background-image: url(https://easyshopnow.in/images/placeholders/page_cover.jpg); */
  background-color: #072341;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.ds__privacy .ds__hero h2 {
  position: relative;
  z-index: 999;
  font-size: 50px;
}
/* .ds__privacy .ds__hero::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
} */
.ds__privacy .ds__content {
  padding: 30px 50px;
}
@media (min-width: 992px) {
  .ds__privacy {
    margin-left: -300px;
  }
}

.ds__privacy input,
.ds__privacy textarea {
  border: 1px solid #072341;
  background-color: transparent;
  padding: 15px;
  width: 100%;
  border-radius: 5px;
}

.ds__privacy .form_control {
  margin-bottom: 15px;
}

.ds__privacy input::placeholder,
.ds__privacy textarea::placeholder {
  color: #000;
}

.ds__privacy button {
  border: none;
  background-color: #072341;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
}

.terms-page-container {
  /* margin: 20px; */
  background-color: #fff;
  /* border-radius: 15px;
  padding: 10px; */
  width: 100vw;
}

/* CustomSwitch.css */

.custom-switch input[type='checkbox'] {
  height: 25px;
  width: 40px;
  margin-top: 0px;
  margin-right: 8px;
}

.custom-switch input[type='checkbox']:checked {
  background-color: #FFB547;
  border-color: #FFB547;
}

/* App.css */

/* AccordionStyles.css */

/* Accordion container styling */
.accordion-container {
  margin: 20px auto;
  /* max-width: 800px; */
}

/* Accordion item styling */
.accordion-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.accordion-header {
  background-color: #f8f9fa;
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
  font-size: 16px;
}

/* Body styling */
.accordion-body {
  padding: 15px;
  background-color: #ffffff;
}

/* Card styling */
.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Card body styling */
.card-body {
  padding: 15px;
}

/* Spacing for inputs and other elements */
.form-control {
  margin-bottom: 10px;
}

.category_label {
  font-weight: bold;
}

.custom-switch {
  margin-top: 20px;
}

.img-avatar {
  border-radius: 50%;
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}


